<template>
  <v-list two-line>
    <v-list-item :key="key" v-for="(message, key) in messages">
      <v-list-item-avatar>
        <v-icon
          :class="(message.is_author ? 'primary' : 'info') + ' white--text'"
        >
          mdi-bullhorn
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          class="grey--text text--darken-1 font-weight-medium break-all"
        >
          {{ message.message }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <span class="primary--text">{{ message.voter.full_name }}</span>
          &mdash; {{ message.created_at | dateTime }}
          <v-icon class="primary--text" small v-if="message.seen === true">
            mdi-check-all
          </v-icon>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action v-if="message.is_author === true">
        <v-btn icon @click="destroy(message)">
          <v-icon color="grey">mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import moment from 'moment'
import handle_errors from '@/api/handle_errors.js'
import catch_errors from '@/api/catch_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'

async function destroy_message(token, message_id) {
  return await fetch(
    `${process.env.VUE_APP_API_URL}/voter/messages/${message_id}`,
    {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export default {
  props: {
    messages: Array,
  },

  filters: {
    dateTime: function (value) {
      if (!value) return ''
      return moment(value).format('DD/MM/YYYY HH:mm')
    },
  },

  methods: {
    destroy(message) {
      destroy_message(localStorage.getItem('token'), message.id)
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(() => {
          this.$mystore.removeMessage(message)
        })
        .catch(response => {
          catch_errors(response, this.$router, this.$mystore)
        })
    },
  },
}
</script>
