var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{attrs:{"title":_vm.title},on:{"logout":function($event){return _vm.$emit('logout')}},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"align-with-title":"","slider-color":"yellow"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),(
          _vm.$mystore.state.event !== null &&
          _vm.$mystore.state.event.live_questions_visibility ===
            'organizer_and_voters'
        )?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('views.communication.allQuestions'))+" ")]):_vm._e(),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('views.communication.myQuestions'))+" ")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","small":"","absolute":"","bottom":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('views.communication.askQuestion')))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"new_message",attrs:{"name":"input-7-1","label":_vm.$t('views.communication.question'),"rules":[
                    function (v) { return !!v || _vm.$t('views.communication.validation.required'); },
                    function (v) { return v.length <= 191 ||
                      _vm.$t('views.communication.validation.max_length'); } ],"counter":"191"},model:{value:(_vm.new_message),callback:function ($$v) {_vm.new_message=$$v},expression:"new_message"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = false
              _vm.$refs.new_message.resetValidation()}}},[_vm._v(_vm._s(_vm.$t('views.communication.close')))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleMessageStore}},[_vm._v(_vm._s(_vm.$t('views.communication.save')))])],1)],1)],1)]},proxy:true}])},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
        _vm.$mystore.state.event !== null &&
        _vm.$mystore.state.event.live_questions_visibility ===
          'organizer_and_voters'
      )?_c('v-tab-item',[_c('MessageList',{attrs:{"messages":_vm.allMessages}})],1):_vm._e(),_c('v-tab-item',[_c('MessageList',{attrs:{"messages":_vm.voterMessages}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }