<template>
  <MainLayout :title="title" v-on:logout="$emit('logout')">
    <template v-slot:extension>
      <v-tabs v-model="tab" align-with-title slider-color="yellow">
        <v-tabs-slider></v-tabs-slider>

        <v-tab
          v-if="
            $mystore.state.event !== null &&
            $mystore.state.event.live_questions_visibility ===
              'organizer_and_voters'
          "
        >
          {{ $t('views.communication.allQuestions') }}
        </v-tab>
        <v-tab>
          {{ $t('views.communication.myQuestions') }}
        </v-tab>
      </v-tabs>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              color="primary"
              fab
              dark
              small
              absolute
              bottom
              right
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{
              $t('views.communication.askQuestion')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="new_message"
                    name="input-7-1"
                    :label="$t('views.communication.question')"
                    ref="new_message"
                    :rules="[
                      v => !!v || $t('views.communication.validation.required'),
                      v =>
                        v.length <= 191 ||
                        $t('views.communication.validation.max_length'),
                    ]"
                    counter="191"
                  ></v-textarea>
                  <!-- hint="Pitanja su javno vidljiva." -->
                </v-col>
              </v-row>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              @click="
                dialog = false
                $refs.new_message.resetValidation()
              "
              >{{ $t('views.communication.close') }}</v-btn
            >
            <v-btn color="primary" @click="handleMessageStore">{{
              $t('views.communication.save')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-if="
          $mystore.state.event !== null &&
          $mystore.state.event.live_questions_visibility ===
            'organizer_and_voters'
        "
      >
        <MessageList :messages="allMessages"></MessageList>
      </v-tab-item>
      <v-tab-item>
        <MessageList :messages="voterMessages"></MessageList>
      </v-tab-item>
    </v-tabs-items>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/Main.vue'
import MessageList from '@/components/MessageList.vue'
import handle_errors from '@/api/handle_errors.js'
import Message from '@/models/Message.js'
import catch_errors from '@/api/catch_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'
import getMessage from '@/api/getMessage.js'

async function get_messages(token) {
  return await fetch(`${process.env.VUE_APP_API_URL}/voter/messages`, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

function map_messages(messages) {
  return messages.map(message => {
    return new Message(message)
  })
}

async function store_message(token, message, socket_id) {
  return await fetch(`${process.env.VUE_APP_API_URL}/voter/messages`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      message: message,
      socket_id: socket_id,
    }),
  })
}

function messages_from_latest_to_oldest(a, b) {
  return a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0
}

export default {
  name: 'Communication',

  components: {
    MainLayout,
    MessageList,
  },

  computed: {
    voterMessages: function () {
      return this.$mystore.state.messages
        .filter(message => {
          if (
            this.$mystore.state.voter !== null &&
            message.voter_id === this.$mystore.state.voter.id
          )
            return true
        })
        .sort(messages_from_latest_to_oldest)
    },

    allMessages: function () {
      return this.$mystore.state.messages
        .map(message => {
          if (
            this.$mystore.state.voter !== null &&
            message.voter_id === this.$mystore.state.voter.id
          )
            message.is_author = true

          return message
        })
        .sort(messages_from_latest_to_oldest)
    },
  },

  data() {
    return {
      title: this.$t('views.communication.title'),
      tab: null,
      dialog: false,
      new_message: '',
      pusherChannel_messages: null,
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.communication.title')
      document.title = this.title
    },

    '$mystore.state.pusherInstance': function (value) {
      if (value === null) return

      this.bindToPusherEvents()
    },
  },

  created: function () {
    document.title = this.title

    this.bindToPusherEvents()

    get_messages(localStorage.getItem('token'))
      .then(handle_token_refresh)
      .then(handle_errors)
      .then(data => {
        this.$mystore.setMessages(map_messages(data.data.messages))
      })
      .catch(response => {
        catch_errors(response, this.$router, this.$mystore)
      })
  },

  destroyed: function () {
    if (this.pusherChannel_messages === null) return

    this.$mystore.state.pusherInstance.unsubscribe(
      `private-encrypted-event_${this.$mystore.state.event.id}_messages`
    )

    this.pusherChannel_messages = null
  },

  methods: {
    bindToPusherEvents() {
      if (this.$mystore.state.pusherInstance === null) return

      this.pusherChannel_messages = this.$mystore.state.pusherInstance.subscribe(
        `private-encrypted-event_${this.$mystore.state.event.id}_messages`
      )

      this.pusherChannel_messages.bind('new', message_id => {
        getMessage(
          message_id,
          data => this.$mystore.addMessage(data.data),
          response => catch_errors(response, this.$router, this.$mystore)
        )
      })

      this.pusherChannel_messages.bind('delete', message_id => {
        this.$mystore.removeMessage(message_id)
      })

      this.pusherChannel_messages.bind('seen', message_id => {
        this.$mystore.markMessageSeen(message_id)
      })
    },

    handleMessageStore() {
      if (!this.$refs.new_message.validate(true)) return

      store_message(
        localStorage.getItem('token'),
        this.new_message,
        this.$mystore.state.pusherInstance.connection.socket_id
      )
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(data => {
          this.$mystore.addMessage(data.data)
        })
        .catch(response => {
          catch_errors(response, this.$router, this.$mystore)
        })

      this.new_message = ''
      this.dialog = false

      // Reset validation.
      this.$refs.new_message.resetValidation()
    },
  },
}
</script>
